import * as THREE from 'three'
import Experience from '../Experience.js'
import { Reflector } from 'three/examples/jsm/objects/Reflector'

export default class Floor {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setGeometry()
        // this.setMirror()
        // this.setTextures()
        // this.setMaterial()
        // this.setMesh()
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(15, 15)
    }

    setMirror() {
        this.groudMirror = new Reflector(this.geometry, {
            clipBias: 0.003,
            textureWidth: window.innerWidth * window.devicePixelRatio,
            textureHeight: window.innerHeight * window.devicePixelRatio,
            color: 0x000000
        })
        this.groudMirror.position.y = - 0.8
        this.groudMirror.rotation.x = - Math.PI * 0.5
        this.scene.add(this.groudMirror)
    }

    setTextures() {
        this.textures = {}

        this.textures.color = this.resources.items.grassColorTexture
        this.textures.color.encoding = THREE.sRGBEncoding
        this.textures.color.repeat.set(1.5, 1.5)
        this.textures.color.wrapS = THREE.RepeatWrapping
        this.textures.color.wrapT = THREE.RepeatWrapping

        this.textures.normal = this.resources.items.grassNormalTexture
        this.textures.normal.repeat.set(1.5, 1.5)
        this.textures.normal.wrapS = THREE.RepeatWrapping
        this.textures.normal.wrapT = THREE.RepeatWrapping
    }

    setMaterial() {
        this.material = new THREE.MeshStandardMaterial({
            color: 0x000000,
            roughness: 0,
            metalness: 1
        })
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.y = - 1
        this.mesh.rotation.x = - Math.PI * 0.5
        this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }
}