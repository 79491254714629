import * as THREE from 'three'
import Experience from "../Experience";
import vertexShader from "./shaders/vertex.glsl"
import fragmentShader from "./shaders/fragment.glsl"

export default class Screen {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setGeometry()
        this.setTextures()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(8, 1.5, 32, 32)
    }

    setTextures() {
        const video = document.createElement("video")
        video.src = 'textures/video/ipsa.mp4'
        video.preload = "auto"
        video.muted = "muted"
        video.loop = "loop"
        this.textures = {}
        this.textures.video = new THREE.VideoTexture(video)
        this.textures.video.wrapS = THREE.RepeatWrapping    
        video.play()
    }

    setMaterial() {
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            uniforms: {
                uTexture: { value: this.textures.video }
            }
            // wireframe: true
        })
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.scene.add(this.mesh)
    }
}